import React from 'react'
import './testimonial.css'
import AVTR1 from '../../assets/avatar1.jpg'
import AVTR2 from '../../assets/avatar2.jpg'
import AVTR3 from '../../assets/avatar3.jpg'
import AVTR4 from '../../assets/avatar4.jpg'

import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const data=[
  {
    avatar:AVTR1,
    name:'Tina Show',
    review:'Impressive Work , deliever on time. Perfect Responsive Website'
  },
  {
    avatar:AVTR2,
    name:'Shatta Wale',
    review:'Impressive Work , deliever on time. Perfect Responsive Website'
  },
  {
    avatar:AVTR3,
    name:'Kwane Despera',
    review:'Impressive Work , deliever on time. Perfect Responsive Website'
  },
  {
    avatar:AVTR4,
    name:'Micky Roy',
    review:'Impressive Work , deliever on time. Perfect Responsive Website'
  }
]
const Testimonials = () => {
  return (
    <section id='testimonials'>
       <h5>Review from Clients</h5>
       <h2>Testimonials</h2>
       <Swiper className='container testimonials__container'
         modules={[Pagination]}
         spaceBetween={40}
         slidesPerView={1}
      pagination={{ clickable: true }}
       
       >
       
        
       {
         data.map(({avatar,name,review})=>{
    return(
      <SwiperSlide className='testimonial'>
      <div className='client__avatar'>
       <img src={avatar} alt='Avatar One'/>
        </div>
        <h5 className='client__name'>{name}</h5>
       <small className='client__review'>{review}</small>
      </SwiperSlide>
       )
     })
          }
       

        
       </Swiper>
    </section>
  )
}

export default Testimonials
