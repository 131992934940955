import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import{RiMessengerLine} from 'react-icons/ri'
import{BsWhatsapp} from 'react-icons/bs'
import {useRef} from 'react'
import emailjs from 'emailjs-com'
const Contact = () => {
  const form=useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ekxa4h4', 'template_rth1j8j', form.current, 'AEWomtgK5CNFg5w3a')
      e.target.reset()
  };
  return (
    <section id='contact'>
       <h5>Get In Touch</h5>
       <h2>Contact Me</h2>
       <div className='container contact__container'>
        <div className='contact__options'>
          <article className='contact__option'>
            <MdOutlineEmail className='contact__option-icon'/>
           <h4>Email</h4>
           <h5>umerarshad260@gmail.com</h5>
           <a href='mailto:umerarshad260@gmail.com' target='_blank'>Send a Message</a>
          </article>

          <article className='contact__option'>
            <RiMessengerLine className='contact__option-icon'/>
           <h4>Messenger</h4>
           <h5>umerarshad</h5>
           <a href='https://www.facebook.com/umer.arshad.1276?mibextid=ZbWKwL' target='_blank'>Send a Message</a>
          </article>

          <article className='contact__option'>
            <BsWhatsapp className='contact__option-icon'/>
           <h4>Whatsapp</h4>
           <h5>+923149690762</h5>
           <a href='https://api.whatsapp.com/send?phone+923149690762' target='_blank'>Send a Message</a>
          </article>
        </div>
        <form ref={form} onSubmit={sendEmail}>
         <input type='text' name='name' placeholder='Your Full Name' required/>
         <input type='email' name='email' placeholder='Your Email' required/>
         <textarea name='message' rows='7' placeholder='Your Message' required></textarea>
         <button type='submit' className='btn btn-primary' >Send Message</button>
         </form> 
        </div> 
    </section>
  )
}

export default Contact
