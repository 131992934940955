import React from "react";
import "./service.css";
import { BiCheck } from "react-icons/bi";
const Services = () => {
  return (
    <section id="services">
      <h5>What I Offer</h5>
      <h2>Services</h2>
      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>Full Stack Development</h3>
          </div>
          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>
                I have expertise in creating visually stunning and highly
                functional web and mobile applications
              </p>
            </li>

            <li>
              <BiCheck className="service__list-icon" />
              <p>
                I excel in front-end development using React and React Native
              </p>
            </li>

            <li>
              <BiCheck className="service__list-icon" />
              <p>
                I possess strong skills in server-side solutions using Laravel
              </p>
            </li>

            <li>
              <BiCheck className="service__list-icon" />
              <p>
                I focus on tailoring web applications to meet specific
                requirements
              </p>
            </li>

            <li>
              <BiCheck className="service__list-icon" />
              <p>
                My websites are designed to deliver exceptional user experiences
              </p>
            </li>

            <li>
              <BiCheck className="service__list-icon" />
              <p>
                I prioritize both aesthetics and functionality in the
                development process
              </p>
            </li>

            <li>
              <BiCheck className="service__list-icon" />
              <p>
                I aim to create websites that drive business growth and success
              </p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  );
};

export default Services;
