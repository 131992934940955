import React from "react";
import "./about.css";
import UmerPng from "../../assets/umer.jpeg";
import { FaAward } from "react-icons/fa";
import { FaBusinessTime } from "react-icons/fa";
import { VscFolderLibrary } from "react-icons/vsc";
const About = () => {
  return (
    <section id="about">
      <h5>Get to Know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={UmerPng} alt="" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>3+ Years Working</small>
            </article>
            {/* <article className="about__card">
              <FaBusinessTime className="about__icon" />
              <h5>Companies Work</h5>
              <small>5</small>
            </article> */}
            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects Completed</h5>
              <small>13+ </small>
            </article>
          </div>
          <p>
            I am a skilled and dedicated Full Stack Developer with expertise in
            PHP, Laravel, React.js and React Native. With a strong passion for
            coding and a keen eye for detail, I strive to deliver efficient and
            high-quality solutions for my clients. Whether you're a client
            looking to hire me for your project or a company seeking a committed
            employee, I bring a wealth of knowledge and experience to the table.
            I thrive in collaborative environments and enjoy working closely
            with teams to create innovative web applications. My commitment to
            continuous learning allows me to stay updated with the latest
            technologies and industry trends, ensuring that I deliver
            cutting-edge solutions. With my problem-solving abilities and a
            strong foundation in full stack development, I am confident in my
            ability to contribute effectively and achieve remarkable results.
          </p>
          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
