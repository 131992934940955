import React from 'react'
import{BsLinkedin} from 'react-icons/bs'
import {SiUpwork} from 'react-icons/si'
import {BsFacebook} from 'react-icons/bs'
const HeaderSocials = () => {
  return (
    <div className='header__socials'>
      <a href='https://www.linkedin.com/in/umer-arshad-a7789021b/' target='_blank'><BsLinkedin/></a>
      <a href='https://www.upwork.com/freelancers/~018aab7ee2d59ca410' target='_blank'><SiUpwork/></a>
      <a href='https://www.fiverr.com/umerarshad967?up_rollout=true' target='_blank'><BsFacebook/></a>
    </div>
  )
}

export default HeaderSocials
