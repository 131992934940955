import React from 'react'
import './footer.css'
import{BsLinkedin} from 'react-icons/bs'
import {SiUpwork} from 'react-icons/si'
import {FaFacebookF} from 'react-icons/fa'
const Footer = () => {
  return (
    <footer>
      <a href='#' className='footer__logo'>Umer Arshad</a>
      <ul className='permalinks'>
        <li><a href='#'>Home</a></li>
        <li><a href='#about'>About</a></li>
        <li><a href='#experience'>Experience</a></li>
        <li><a href='#services'>Services</a></li>
        <li><a href='#portfolio'>Portfolio</a></li>
        <li><a href='#testimonials'>Testimonials</a></li>
        <li><a href='#contact'>Contact</a></li>
        </ul> 
        <div className='footer__socials'>
         <a href='https://www.fiverr.com/umerarshad967?up_rollout=true' target='_blank'><FaFacebookF/></a> 
         <a href='https://www.linkedin.com/in/umer-arshad-a7789021b/' target='_blank'><BsLinkedin/></a>  
         <a href='https://www.upwork.com/freelancers/~018aab7ee2d59ca410' target='_blank'><SiUpwork/></a> 
        </div>
        <div className='footer__copyright'>
         <small>&copy;Umer Arshad.All rights reserved</small>
        </div>
    </footer>
  )
}

export default Footer
